import { Direction, LandingTheme, TemplateNames } from '@anm/api/modules/hosting';
import TitleBlock, { TitleDescription } from '@anm/components/TitleBlock';
import { LandingCTAWrapper } from '@anm/components/video-landings/LandingCTA';
import { device } from '@anm/styles/helpers';
import { textColorVariants } from '@anm/styles/landingThemeStyles';
import styled, { css } from 'styled-components';

export type WrapperProps = {
  layoutVariant: TemplateNames;
  layoutDirection: Direction;
  isNoMeta?: boolean;
  maxWidth?: number;
};

export type LandingMetaWrapperProps = {
  playerColor: string;
  colorTheme?: LandingTheme;
  fontStyles?: { [key in 'headline' | 'body']?: ReturnType<typeof css> };
};

export const LogoWrapper = styled.div`
  margin-bottom: 26px;
`;

export const CTAWrapper = styled.div`
  margin-bottom: 32px;
  &:empty {
    display: none;
  }
`;

export const LandingTitle = styled(TitleBlock)``;

export const LandingMetaWrapper = styled.div<LandingMetaWrapperProps>`
  color: #292a2b;
  margin: 0 auto;
  ${({ colorTheme }) => textColorVariants[colorTheme || 'pure']}

  ${({ fontStyles }) =>
    fontStyles &&
    css`
      ${LandingTitle} {
        h1:first-child {
          ${fontStyles.headline};
          font-size: 36px;
          line-height: 50px;
          margin: 0 0 20px 0;
          text-align: center;
        }

        ${TitleDescription} {
          ${fontStyles.body};
          font-size: 16px;
          line-height: 28px;
          margin-bottom: 26px;
        }
      }
    `}
`;

const rowDirectionBasicTemplate = css`
  @media ${device.laptop} {
    max-width: 420px;
    margin-right: 40px;
    text-align: left;
  }

  ${LandingMetaWrapper} {
    & > div:not(:first-child) {
      display: none;

      @media ${device.laptop} {
        display: block;
      }
    }
  }
`;

const columnDirectionBasicTemplate = css`
  ${LandingCTAWrapper} {
    display: none;
  }
`;

const templateFourFiveWithoutMeta = css`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    margin-right: 0;
  }
`;

const templateFourFiveWithMeta = css`
  @media ${device.laptop} {
    & > div {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const layoutVariants: { [key in TemplateNames]: ReturnType<typeof css> } = {
  basic: css`
    ${({ layoutDirection }: WrapperProps) =>
      layoutDirection === 'row' ? rowDirectionBasicTemplate : columnDirectionBasicTemplate};

    ${LandingMetaWrapper} {
      max-width: 600px;
    }
  `,
  t1: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    ${LandingMetaWrapper} {
      max-width: 720px;
    }
  `,
  t2: css`
    text-align: left;

    @media ${device.laptop} {
      max-width: 400px;
      margin-right: 40px;
      align-self: center;

      & + div {
        margin: 0;
      }
    }

    @media ${device.desktop} {
      max-width: 500px;
    }
  `,
  t3: css`
    margin-top: 26px;
    text-align: left;

    @media ${device.laptop} {
      margin-top: 0;
      max-width: 400px;
      margin-left: 40px;
      align-self: center;
    }

    ${LogoWrapper} {
      display: none;

      @media ${device.laptop} {
        display: block;
      }
    }

    @media ${device.desktop} {
      max-width: 500px;
    }
  `,
  t4: css`
    text-align: left;
    ${({ isNoMeta }: WrapperProps) =>
      isNoMeta
        ? css`
            ${templateFourFiveWithoutMeta}
          `
        : css`
            ${templateFourFiveWithMeta}
          `};

    @media ${device.laptop} {
      margin: 0 auto;
    }

    .title_block {
      @media ${device.laptop} {
        min-width: 400px;
        max-width: 720px;
      }

      & > *:not(h1) {
        display: none;

        @media ${device.laptop} {
          display: block;
          font-size: 16px;
          line-height: 28px;
          margin-bottom: 26px;
          white-space: pre-wrap;
        }
      }
    }

    .cta_wrapper > div {
      margin-top: 0;

      @media ${device.laptop} {
        margin-left: 40px;
      }
    }
  `,
  t5: css`
    text-align: left;
    margin: 48px auto 0;
    ${({ isNoMeta }: WrapperProps) =>
      isNoMeta
        ? css`
            ${templateFourFiveWithoutMeta}
          `
        : css`
            @media ${device.laptop} {
              ${templateFourFiveWithMeta}
            }
          `};

    .cta_wrapper > div {
      margin-top: 0;

      @media ${device.laptop} {
        margin-left: 40px;
      }
    }

    @media ${device.laptop} {
      .title_block {
        min-width: 400px;
        max-width: 720px;
      }
    }
  `
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}

  ${({ layoutVariant }) => layoutVariants[layoutVariant || 'basic']}
`;
