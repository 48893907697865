import { flexCenter } from '@anm/styles/helpers';
import { LandingTheme } from '@anm/api/modules/hosting';
import Button, { ButtonProps } from '@anm/components/buttons/Button';
import { landingCTAVariants } from '@anm/styles/landingThemeStyles';
import styled, { css } from 'styled-components';

type CTAButtonProps = {
  bgColor: string;
  buttonTheme: LandingTheme;
  fontStyles?: ReturnType<typeof css>;
};

export const LandingCTAWrapper = styled.div<{ isCentered?: boolean }>`
  margin: 32px auto 0;

  ${({ isCentered }) =>
    isCentered &&
    css`
      ${flexCenter};
    `}
`;

export const CTAButton = styled(Button)<ButtonProps & CTAButtonProps>`
  transition: 0.3s;
  padding: 14px 20px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: unset;
  line-height: unset;
  color: #fff;
  background: transparent;
  border-radius: 0;
  min-width: 140px;

  ${({ fontStyles }) => fontStyles && `${fontStyles}`};
  ${({ buttonTheme }) => landingCTAVariants[buttonTheme]}
`;
