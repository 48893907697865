import React from "react";
var __jsx = React.createElement;
import Error from '@anm/components/video-landings/Error';
import Layout from '@anm/components/video-landings/Layout';
import { DEFAULT_WAVE_COLOR } from '@anm/constants/colors';
import MetaHead from '../src/components/MetaHead';
var title = 'This page is currently private';
var description = 'Please ask the owner of the video to enable sharing.';
var iconSrc = 'images/video-landings/icons/see-no-evil.png';

var PrivatePage = function PrivatePage(props) {
  return __jsx(React.Fragment, null, __jsx(MetaHead, props), __jsx(Layout, {
    variant: "pure",
    playerColor: DEFAULT_WAVE_COLOR,
    hasFooter: false
  }, __jsx(Error, {
    title: title,
    iconSrc: iconSrc,
    description: description
  })));
};

export default PrivatePage;