import _extends from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _slicedToArray from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import React from "react";
var __jsx = React.createElement;
import PlayerWrapper from '@anm/components/PlayerWrapper';
import Container from '@anm/components/video-landings/Container';
import DataWrapper from '@anm/components/video-landings/DataWrapper';
import LandingCTA from '@anm/components/video-landings/LandingCTA';
import parseTemplateProps from '../../../helpers/parseTemplateProps';
import useContainerHeight from '../../../hooks/useContainerHeight';
import useFontStyles from '@anm/hooks/useFontStyles';
import usePlayerSizesByHeight from '../../../hooks/usePlayerSizesByHeight';
import useTemplateRefs from '../../../hooks/useTemplateRefs';

var TemplateOne = function TemplateOne(_ref) {
  var videoId = _ref.videoId,
      viewType = _ref.viewType,
      playerColor = _ref.playerColor,
      startTime = _ref.startTime,
      otherProps = _objectWithoutProperties(_ref, ["videoId", "viewType", "playerColor", "startTime"]);

  var _parseTemplateProps = parseTemplateProps(otherProps),
      cta = _parseTemplateProps.cta,
      meta = _parseTemplateProps.meta,
      theme = _parseTemplateProps.theme,
      logoUrl = _parseTemplateProps.logoUrl,
      hasFooter = _parseTemplateProps.hasFooter,
      previewSrc = _parseTemplateProps.previewSrc,
      videoRatio = _parseTemplateProps.videoRatio,
      playerProps = _parseTemplateProps.playerProps,
      isCustomization = _parseTemplateProps.isCustomization;

  var _useFontStyles = useFontStyles(),
      _useFontStyles2 = _slicedToArray(_useFontStyles, 2),
      buttonFontStyles = _useFontStyles2[0],
      metaFontStyles = _useFontStyles2[1];

  var _useTemplateRefs = useTemplateRefs(),
      containerRef = _useTemplateRefs.containerRef,
      playerWrapperRef = _useTemplateRefs.playerWrapperRef,
      landingCTARef = _useTemplateRefs.landingCTARef;

  var playerSizes = usePlayerSizesByHeight({
    viewType: viewType,
    hasFooter: hasFooter,
    videoRatio: videoRatio,
    containerRef: containerRef,
    landingCTARef: landingCTARef,
    playerWrapperRef: playerWrapperRef
  });
  var variant = 't1';
  var containerHeight = useContainerHeight({
    hasFooter: hasFooter,
    containerRef: containerRef,
    isCustomization: isCustomization
  });
  return __jsx(Container, {
    variant: variant,
    hasFooter: hasFooter,
    isCustomization: isCustomization,
    viewType: viewType,
    ref: containerRef,
    minHeight: containerHeight
  }, __jsx(DataWrapper, {
    cta: cta,
    meta: meta,
    logoUrl: logoUrl,
    videoId: videoId,
    playerColor: playerColor,
    isCTA: false,
    colorTheme: theme,
    layoutVariant: variant,
    fontStyles: metaFontStyles
  }), __jsx(PlayerWrapper, _extends({
    videoName: meta === null || meta === void 0 ? void 0 : meta.heading
  }, playerProps, {
    videoId: videoId,
    startTime: startTime,
    playerSizes: playerSizes,
    previewSrc: previewSrc,
    playerWrapperRef: playerWrapperRef
  })), __jsx(LandingCTA, {
    cta: cta,
    theme: theme,
    playerColor: playerColor,
    CTAref: landingCTARef,
    projectId: videoId,
    isCentered: true,
    fontStyles: buttonFontStyles
  }));
};

export default TemplateOne;