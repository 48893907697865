import _slicedToArray from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { DESKTOP_BREAKPOINT } from '@anm/constants/videoLandings';
import getBiggestValue from '@anm/helpers/getBiggestValue';
import getContainerPaddings from '@anm/helpers/getContainerPaddings';
import getElementOuterWidth from '@anm/helpers/getElementOuterWidth';
import getPlayerMinSizes from '@anm/helpers/getPlayerMinSizes';
import { useEffect, useState } from 'react';
import getPlayerSizes from '../helpers/getPlayerSizes';

var usePlayersizesByWidth = function usePlayersizesByWidth(_ref) {
  var viewType = _ref.viewType,
      videoRatio = _ref.videoRatio,
      containerRef = _ref.containerRef,
      dataWrapperRef = _ref.dataWrapperRef,
      playerWrapperRef = _ref.playerWrapperRef,
      maxWidthOfScreen = _ref.maxWidthOfScreen,
      _ref$noCalculate = _ref.noCalculate,
      noCalculate = _ref$noCalculate === void 0 ? false : _ref$noCalculate,
      _ref$layoutDirection = _ref.layoutDirection,
      layoutDirection = _ref$layoutDirection === void 0 ? 'column' : _ref$layoutDirection;

  var _useState = useState(),
      playerSizes = _useState[0],
      setPlayerSizes = _useState[1];

  var _useState2 = useState(),
      playerMaxWidth = _useState2[0],
      setPlayerMaxWidth = _useState2[1];

  useEffect(function () {
    if (!playerMaxWidth || !dataWrapperRef.current || !containerRef.current || noCalculate) {
      return;
    }

    var sizes = getPlayerSizes({
      videoRatio: videoRatio,
      maxWidth: playerMaxWidth,
      container: containerRef.current
    });
    sizes && setPlayerSizes({
      width: sizes.width,
      height: sizes.height
    });
  }, [playerMaxWidth, dataWrapperRef, containerRef, viewType]);
  useEffect(function () {
    var _dataWrapperRef$curre;

    if (!dataWrapperRef.current || !containerRef.current || !playerWrapperRef.current || noCalculate) {
      return;
    }

    var page = document.documentElement;
    var pageWidth = page.offsetWidth;
    var isMobile = pageWidth < DESKTOP_BREAKPOINT;

    var _getContainerPaddings = getContainerPaddings(containerRef.current),
        _getContainerPaddings2 = _slicedToArray(_getContainerPaddings, 2),
        horizontalPaddings = _getContainerPaddings2[1];

    var _getPlayerMinSizes = getPlayerMinSizes(videoRatio),
        _getPlayerMinSizes2 = _slicedToArray(_getPlayerMinSizes, 1),
        minPlayerWidth = _getPlayerMinSizes2[0];

    var screenPercentageWidth = maxWidthOfScreen && maxWidthOfScreen * pageWidth;
    var dataWrapperOuterWidth = isMobile ? 0 : getElementOuterWidth(dataWrapperRef.current);
    var calculatedWidth = screenPercentageWidth || (layoutDirection === 'column' ? dataWrapperRef === null || dataWrapperRef === void 0 ? void 0 : (_dataWrapperRef$curre = dataWrapperRef.current) === null || _dataWrapperRef$curre === void 0 ? void 0 : _dataWrapperRef$curre.offsetWidth : pageWidth - horizontalPaddings - dataWrapperOuterWidth);
    var canUseCalculatedWidth = layoutDirection === 'row' && calculatedWidth < minPlayerWidth && !isMobile;
    var maxWidth = canUseCalculatedWidth ? calculatedWidth : getBiggestValue(calculatedWidth, minPlayerWidth);
    setPlayerMaxWidth(maxWidth);
  }, [dataWrapperRef, containerRef, playerWrapperRef, viewType]);
  return playerSizes;
};

export default usePlayersizesByWidth;