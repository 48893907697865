import usePlayerSizesByHeight from './usePlayerSizesByHeight';
import usePlayerSizesByWidth from './usePlayerSizesByWidth';

var useDynamicPlayerSizes = function useDynamicPlayerSizes(_ref) {
  var viewType = _ref.viewType,
      videoRatio = _ref.videoRatio,
      calculateBy = _ref.calculateBy,
      containerRef = _ref.containerRef,
      landingCTARef = _ref.landingCTARef,
      landingMetaRef = _ref.landingMetaRef,
      layoutDirection = _ref.layoutDirection,
      dataWrapperRef = _ref.dataWrapperRef,
      playerWrapperRef = _ref.playerWrapperRef,
      maxWidthOfScreen = _ref.maxWidthOfScreen,
      maxHeightOfScreen = _ref.maxHeightOfScreen;
  var isVertical = videoRatio <= 1;
  var calculationDirection = typeof calculateBy !== 'undefined' ? calculateBy : isVertical ? 'height' : 'width';
  var calculateByWidth = calculationDirection === 'width';
  var calculateByHeight = calculationDirection === 'height';
  var verticalSizes = usePlayerSizesByHeight({
    viewType: viewType,
    videoRatio: videoRatio,
    containerRef: containerRef,
    playerWrapperRef: playerWrapperRef,
    landingCTARef: landingCTARef,
    layoutDirection: layoutDirection,
    landingMetaRef: landingMetaRef,
    maxHeightOfScreen: maxHeightOfScreen,
    noCalculate: calculateByWidth
  });
  var horizontalSizes = usePlayerSizesByWidth({
    viewType: viewType,
    videoRatio: videoRatio,
    containerRef: containerRef,
    dataWrapperRef: dataWrapperRef,
    layoutDirection: layoutDirection,
    maxWidthOfScreen: maxWidthOfScreen,
    playerWrapperRef: playerWrapperRef,
    noCalculate: calculateByHeight
  });
  return calculateByWidth ? horizontalSizes : verticalSizes;
};

export default useDynamicPlayerSizes;