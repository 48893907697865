const VERTICAL_MIN_WIDTH = 230;
const HORIZONTAL_MIN_WIDTH = 600;

const getPlayerMinSizes = (ratio: number) => {
  const isVertical = ratio <= 1;
  const minPlayerWidth = isVertical ? VERTICAL_MIN_WIDTH : HORIZONTAL_MIN_WIDTH;

  const minPlayerHeight = minPlayerWidth / ratio;

  return [minPlayerWidth, minPlayerHeight] as const;
};

export default getPlayerMinSizes;
