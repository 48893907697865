import getBestMedia from '@anm/helpers/getBestMedia';
import { isVideoBlocked, Manifest, Target } from '@anm/hosting-player';
import { useEffect, FC, RefObject } from 'react';

declare global {
  interface Window {
    onPlayerInit?: () => void;
  }
}

type PlayerContainerProps = {
  videoId: string;
  startTime?: number;
  manifest?: Manifest;
  playerContainerRef?: RefObject<HTMLDivElement>;
  onPlayerInit?: () => void;
} & Target;

const PlayerContainer: FC<PlayerContainerProps> = ({
  target,
  videoId,
  manifest,
  startTime,
  onPlayerInit,
  playerContainerRef
}) => {
  const bestVideo = manifest && !isVideoBlocked(manifest) && getBestMedia(manifest.main, 'video');

  useEffect(() => {
    window.onPlayerInit = onPlayerInit;
  }, []);

  const { width, height } = bestVideo || {};

  return (
    <div
      ref={playerContainerRef}
      className="wave_embed"
      data-id={videoId}
      data-width={width}
      data-height={height}
      data-target={target}
      data-time={startTime}
      data-manifest={JSON.stringify(manifest)}
    />
  );
};

export default PlayerContainer;
