import { Direction, FormatNames, TemplateNames } from '@anm/api/modules/hosting';
import { DEFAULT_MOBILE_HEIGHT, DESKTOP_FOOTER_HEIGHT, MOBILE_FOOTER_HEIGHT } from '@anm/constants/videoLandings';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';
import { device } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

type ContainerProps = {
  variant: TemplateNames;
  viewType?: FormatNames;
  minHeight?: number;
  hasFooter?: boolean;
  topAligned?: boolean;
  isCustomization?: boolean;
  layoutDirection?: Direction;
};

const containerDirectionVariants: {
  [key in Direction]: ReturnType<typeof css>;
} = {
  row: css`
    @media ${device.laptop} {
      display: flex;

      align-items: ${({ topAligned }: ContainerProps) => (topAligned ? 'flex-start' : 'center')};
      justify-content: center;
    }
  `,
  column: css``
};

const templateVariants: { [key in TemplateNames]: ReturnType<typeof css> } = {
  basic: css`
    padding: 48px 19px;

    @media ${device.laptop} {
      padding: 48px 60px;
    }

    @media ${device.laptop} {
      margin: 0 auto;
      padding: 48px 60px;

      ${({ layoutDirection }: ContainerProps) =>
        layoutDirection === 'column'
          ? css`
              text-align: center;
            `
          : css`
              & > div {
                &:not(:first-child) {
                  @media ${device.laptop} {
                    margin: 0;
                  }
                }

                &:nth-child(3) {
                  @media ${device.laptop} {
                    display: none;
                  }
                }
              }
            `}
    }
  `,
  t1: css`
    padding: 48px 19px;

    @media ${device.laptop} {
      padding: 48px 60px;
    }
  `,
  t2: css`
    padding: 48px 19px;

    @media ${device.laptop} {
      padding: 48px 60px;
    }
  `,
  t3: css`
    padding: 48px 19px;

    @media ${device.laptop} {
      padding: 48px 60px;
      & > div:not(:last-child) {
        margin: 0;
      }
    }
  `,
  t4: css`
    padding: 48px 19px;

    @media ${device.laptop} {
      padding: 48px 60px;
    }
  `,
  t5: css`
    padding: 48px 19px 92px;

    @media ${device.laptop} {
      padding: 48px 19px 80px;
    }
  `
};

export const FOOTER_HEIGHT = isMobileOrIPad() ? DESKTOP_FOOTER_HEIGHT : MOBILE_FOOTER_HEIGHT;

const getFooterHeight = (hasFooter?: boolean, viewType?: FormatNames) => {
  const isMobileView = viewType === 'mobile';
  const footerHeight = isMobileView ? DESKTOP_FOOTER_HEIGHT : hasFooter ? FOOTER_HEIGHT : 0;

  return footerHeight;
};

const Container = styled.div<ContainerProps>`
  margin: 0 auto;
  ${({ variant }) => templateVariants[variant]}

  min-height: ${({ hasFooter, minHeight, viewType, isCustomization = false }) =>
    minHeight
      ? `${minHeight}px`
      : !isCustomization
      ? `calc(100vh - ${getFooterHeight(hasFooter)}px)`
      : `calc(${DEFAULT_MOBILE_HEIGHT}px - ${getFooterHeight(hasFooter, viewType)}px)`}; 

  ${({ layoutDirection }) => containerDirectionVariants[layoutDirection || 'column']};


  pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    background-color: #f8f8f8;
    border: 1px solid #dfdfdf;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    padding: 0.125rem 0.3125rem 0.0625rem;
  }

  ul {
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.6;
    list-style-position: outside;
    margin-bottom: 1.25rem;
    margin-left: 1.1rem;

    li {
      margin: 0;
      padding: 0;
    }
  }

`;

export default Container;
