import { useEffect, useState } from 'react';

var useIsTopAligned = function useIsTopAligned(_ref) {
  var dataWrapperRef = _ref.dataWrapperRef;

  var _useState = useState(false),
      isTopAligned = _useState[0],
      setIsTopAligned = _useState[1];

  useEffect(function () {
    if (!dataWrapperRef.current) return;
    var pageHeight = document.documentElement.offsetHeight;
    var dataWrapperHeight = dataWrapperRef.current.offsetHeight;
    var isTopAligned = dataWrapperHeight > pageHeight;
    isTopAligned && setIsTopAligned(isTopAligned);
  }, [dataWrapperRef]);
  return isTopAligned;
};

export default useIsTopAligned;