import _extends from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _slicedToArray from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import React from "react";
var __jsx = React.createElement;
import PlayerWrapper from '@anm/components/PlayerWrapper';
import Container from '@anm/components/video-landings/Container';
import CustomLogo from '@anm/components/video-landings/CustomLogo';
import DataWrapper from '@anm/components/video-landings/DataWrapper';
import { device } from '@anm/styles/helpers';
import styled from 'styled-components';
import parseTemplateProps from '../../../helpers/parseTemplateProps';
import useContainerHeight from '../../../hooks/useContainerHeight';
import useDynamicPlayerSizes from '../../../hooks/useDynamicPlayerSizes';
import useFontStyles from '@anm/hooks/useFontStyles';
import useIsTopAligned from '../../../hooks/useIsTopAligned';
import useTemplateRefs from '../../../hooks/useTemplateRefs';
var LogoWrapper = styled.div.withConfig({
  componentId: "oj5g4e-0"
})(["margin-bottom:26px;@media ", "{display:none;}"], device.laptop);

var TemplateThree = function TemplateThree(_ref) {
  var _parameters$meta;

  var videoId = _ref.videoId,
      viewType = _ref.viewType,
      playerColor = _ref.playerColor,
      startTime = _ref.startTime,
      otherProps = _objectWithoutProperties(_ref, ["videoId", "viewType", "playerColor", "startTime"]);

  var _parseTemplateProps = parseTemplateProps(otherProps),
      cta = _parseTemplateProps.cta,
      meta = _parseTemplateProps.meta,
      isCTA = _parseTemplateProps.isCTA,
      theme = _parseTemplateProps.theme,
      logoUrl = _parseTemplateProps.logoUrl,
      hasFooter = _parseTemplateProps.hasFooter,
      previewSrc = _parseTemplateProps.previewSrc,
      videoRatio = _parseTemplateProps.videoRatio,
      parameters = _parseTemplateProps.parameters,
      playerProps = _parseTemplateProps.playerProps,
      isCustomization = _parseTemplateProps.isCustomization;

  var _useFontStyles = useFontStyles(),
      _useFontStyles2 = _slicedToArray(_useFontStyles, 2),
      buttonFontStyles = _useFontStyles2[0],
      metaFontStyles = _useFontStyles2[1];

  var variant = 't3';
  var layoutDirection = 'row';

  var _useTemplateRefs = useTemplateRefs(),
      containerRef = _useTemplateRefs.containerRef,
      playerWrapperRef = _useTemplateRefs.playerWrapperRef,
      landingCTARef = _useTemplateRefs.landingCTARef,
      dataWrapperRef = _useTemplateRefs.dataWrapperRef,
      landingMetaRef = _useTemplateRefs.landingMetaRef;

  var playerSizes = useDynamicPlayerSizes({
    viewType: viewType,
    videoRatio: videoRatio,
    containerRef: containerRef,
    landingCTARef: landingCTARef,
    landingMetaRef: landingMetaRef,
    layoutDirection: layoutDirection,
    dataWrapperRef: dataWrapperRef,
    playerWrapperRef: playerWrapperRef
  });
  var isTopAligned = useIsTopAligned({
    dataWrapperRef: dataWrapperRef
  });
  var containerHeight = useContainerHeight({
    hasFooter: hasFooter,
    containerRef: containerRef,
    isCustomization: isCustomization
  });
  return __jsx(Container, {
    variant: variant,
    hasFooter: hasFooter,
    layoutDirection: layoutDirection,
    isCustomization: isCustomization,
    viewType: viewType,
    ref: containerRef,
    minHeight: containerHeight,
    topAligned: isTopAligned
  }, logoUrl && __jsx(LogoWrapper, null, __jsx(CustomLogo, {
    logoUrl: logoUrl,
    alt: (parameters === null || parameters === void 0 ? void 0 : (_parameters$meta = parameters.meta) === null || _parameters$meta === void 0 ? void 0 : _parameters$meta.heading) || logoUrl
  })), __jsx(PlayerWrapper, _extends({
    videoName: meta === null || meta === void 0 ? void 0 : meta.heading
  }, playerProps, {
    videoId: videoId,
    startTime: startTime,
    playerSizes: playerSizes,
    previewSrc: previewSrc,
    playerWrapperRef: playerWrapperRef
  })), __jsx(DataWrapper, {
    cta: cta,
    meta: meta,
    isCTA: isCTA,
    logoUrl: logoUrl,
    videoId: videoId,
    playerColor: playerColor,
    landingCTARef: landingCTARef,
    dataWrapperRef: dataWrapperRef,
    landingMetaRef: landingMetaRef,
    colorTheme: theme,
    layoutVariant: variant,
    CTAFontStyles: buttonFontStyles,
    fontStyles: metaFontStyles
  }));
};

export default TemplateThree;