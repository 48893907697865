import styled, { css } from 'styled-components';

import { Sizes } from '.';

type WrapperProps = {
  resized: boolean;
  playerInited?: boolean;
} & Partial<Sizes>;

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  position: relative;
  margin: 0 auto;
  opacity: ${({ width, height, playerInited }) =>
    width || height || playerInited ? 1 : 0};

  ${({ width, height, resized }) =>
    width &&
    height &&
    css`
      max-width: ${width}px;
      min-height: ${resized ? 'auto' : `${height}px`};
    `};
`;

export const Preview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const ContainerWrapper = styled.div<{ isInited: boolean }>`
  opacity: ${({ isInited }) => (isInited ? 1 : 0)};
`;
