import React from "react";
var __jsx = React.createElement;
import BasicTemplate from '../Basic';
import TemplateFive from '../TemplateFive';
import TemplateFour from '../TemplateFour';
import TemplateOne from '../TemplateOne';
import TemplateThree from '../TemplateThree';
import TemplateTwo from '../TemplateTwo';

var Template = function Template(props) {
  var templateName = props.page.template;

  switch (templateName) {
    // TODO: discuss possilbility to get rid of basic template
    case 'basic':
      return __jsx(BasicTemplate, props);

    case 't1':
      return __jsx(TemplateOne, props);

    case 't2':
      return __jsx(TemplateTwo, props);

    case 't3':
      return __jsx(TemplateThree, props);

    case 't4':
      return __jsx(TemplateFour, props);

    case 't5':
      return __jsx(TemplateFive, props);

    default:
      return null;
  }
};

export default Template;