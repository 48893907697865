import { useRef } from 'react';

var useTemplateRefs = function useTemplateRefs() {
  var containerRef = useRef(null);
  var landingCTARef = useRef(null);
  var landingMetaRef = useRef(null);
  var dataWrapperRef = useRef(null);
  var playerWrapperRef = useRef(null);
  return {
    containerRef: containerRef,
    landingCTARef: landingCTARef,
    landingMetaRef: landingMetaRef,
    dataWrapperRef: dataWrapperRef,
    playerWrapperRef: playerWrapperRef
  };
};

export default useTemplateRefs;