import { analyticsCTAUrl, sendDataUrl } from '@anm/analytic/playerAnalytic';
import { LandingCTAProps } from '@anm/api/modules/hosting';
import { useCallback, useEffect, useRef } from 'react';

const useCTAClick = (projectId: string, cta?: LandingCTAProps) => {
  const id = useRef({ viewId: '' });

  const onReceiveMessage = useCallback(({ data: { viewId } }: MessageEvent) => {
    if (!viewId) return;

    id.current.viewId = viewId;
  }, []);

  useEffect(() => {
    window.addEventListener('message', onReceiveMessage);

    return () => window.removeEventListener('message', onReceiveMessage);
  }, []);

  const onCTAClick = useCallback(() => {
    sendDataUrl(analyticsCTAUrl)([
      {
        projectId,
        type: 'click',
        event: 'action',
        viewId: id.current.viewId,
        meta: {
          ctaLink: cta?.buttonLink,
          ctaText: cta?.buttonText
        }
      }
    ]);
  }, [cta?.buttonLink, cta?.buttonText]);

  return onCTAClick;
};

export default useCTAClick;
