import { TemplateNames } from '@anm/api/modules/hosting';
import { HORIZONTAL_TEMPLATE, OUTDATED_TEMPLATE, VERTICAL_TEMPLATE } from '@anm/constants/videoLandings';
import { VideoSizes } from '@anm/hosting-player';

const getLandingTemplate = (template: TemplateNames, sizes?: VideoSizes, ratio?: number) => {
  if (template !== OUTDATED_TEMPLATE || !sizes) return template;

  return ratio || sizes.width / sizes.height > 1 ? HORIZONTAL_TEMPLATE : VERTICAL_TEMPLATE;
};

export default getLandingTemplate;
