const getContainerPaddings = (container: Element) => {
  const {
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom
  } = window.getComputedStyle(container);

  const verticalPaddings = parseFloat(paddingTop) + parseFloat(paddingBottom);
  const horizontalPaddings = parseFloat(paddingLeft) + parseFloat(paddingRight);

  return [verticalPaddings, horizontalPaddings] as const;
};

export default getContainerPaddings;
