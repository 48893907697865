import _extends from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _slicedToArray from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import React from "react";
var __jsx = React.createElement;
import PlayerWrapper from '@anm/components/PlayerWrapper';
import Container from '@anm/components/video-landings/Container';
import DataWrapper, { LandingMetaWrapper, LandingTitle } from '@anm/components/video-landings/DataWrapper';
import { DATA_WRAPPER_WIDTH } from '@anm/constants/videoLandings';
import getBiggestValue from '@anm/helpers/getBiggestValue';
import { device } from '@anm/styles/helpers';
import styled from 'styled-components';
import parseTemplateProps from '../../../helpers/parseTemplateProps';
import useContainerHeight from '../../../hooks/useContainerHeight';
import useDynamicPlayerSizes from '../../../hooks/useDynamicPlayerSizes';
import useFontStyles from '@anm/hooks/useFontStyles';
import useTemplateRefs from '../../../hooks/useTemplateRefs';
var LandingTextWrapper = styled.div.withConfig({
  componentId: "sc-13qqi0u-0"
})(["margin-top:32px;text-align:left;@media ", "{display:none;}"], device.laptop);

var TemplateFour = function TemplateFour(_ref) {
  var videoId = _ref.videoId,
      viewType = _ref.viewType,
      playerColor = _ref.playerColor,
      startTime = _ref.startTime,
      otherProps = _objectWithoutProperties(_ref, ["videoId", "viewType", "playerColor", "startTime"]);

  var _parseTemplateProps = parseTemplateProps(otherProps),
      cta = _parseTemplateProps.cta,
      meta = _parseTemplateProps.meta,
      isCTA = _parseTemplateProps.isCTA,
      theme = _parseTemplateProps.theme,
      logoUrl = _parseTemplateProps.logoUrl,
      hasFooter = _parseTemplateProps.hasFooter,
      previewSrc = _parseTemplateProps.previewSrc,
      videoRatio = _parseTemplateProps.videoRatio,
      playerProps = _parseTemplateProps.playerProps,
      layoutDirection = _parseTemplateProps.layoutDirection,
      isCustomization = _parseTemplateProps.isCustomization,
      isOnlyCTAOrLogo = _parseTemplateProps.isOnlyCTAOrLogo;

  var _useFontStyles = useFontStyles(),
      _useFontStyles2 = _slicedToArray(_useFontStyles, 2),
      buttonFontStyles = _useFontStyles2[0],
      metaFontStyles = _useFontStyles2[1];

  var _useTemplateRefs = useTemplateRefs(),
      containerRef = _useTemplateRefs.containerRef,
      playerWrapperRef = _useTemplateRefs.playerWrapperRef,
      landingCTARef = _useTemplateRefs.landingCTARef,
      dataWrapperRef = _useTemplateRefs.dataWrapperRef,
      landingMetaRef = _useTemplateRefs.landingMetaRef;

  var playerSizes = useDynamicPlayerSizes({
    viewType: viewType,
    videoRatio: videoRatio,
    containerRef: containerRef,
    landingCTARef: landingCTARef,
    landingMetaRef: landingMetaRef,
    dataWrapperRef: dataWrapperRef,
    playerWrapperRef: playerWrapperRef,
    layoutDirection: layoutDirection,
    calculateBy: 'height',
    maxHeightOfScreen: 0.8
  });
  var containerHeight = useContainerHeight({
    hasFooter: hasFooter,
    containerRef: containerRef,
    isCustomization: isCustomization
  });
  var variant = 't4';
  var description = meta === null || meta === void 0 ? void 0 : meta.bodyText;
  var dataWrapperWidth = isOnlyCTAOrLogo ? playerSizes === null || playerSizes === void 0 ? void 0 : playerSizes.width : getBiggestValue((playerSizes === null || playerSizes === void 0 ? void 0 : playerSizes.width) || 0, DATA_WRAPPER_WIDTH);
  return __jsx(Container, {
    variant: variant,
    hasFooter: hasFooter,
    isCustomization: isCustomization,
    viewType: viewType,
    ref: containerRef,
    minHeight: containerHeight
  }, __jsx(DataWrapper, {
    cta: cta,
    meta: meta,
    isCTA: isCTA,
    logoUrl: logoUrl,
    videoId: videoId,
    playerColor: playerColor,
    dataWrapperRef: dataWrapperRef,
    landingMetaRef: landingMetaRef,
    colorTheme: theme,
    layoutVariant: variant,
    maxWidth: dataWrapperWidth,
    CTAFontStyles: buttonFontStyles,
    fontStyles: metaFontStyles
  }), __jsx(PlayerWrapper, _extends({
    videoName: meta === null || meta === void 0 ? void 0 : meta.heading
  }, playerProps, {
    videoId: videoId,
    startTime: startTime,
    playerSizes: playerSizes,
    previewSrc: previewSrc,
    playerWrapperRef: playerWrapperRef
  })), description && __jsx(LandingTextWrapper, null, __jsx(LandingMetaWrapper, {
    playerColor: playerColor,
    ref: landingMetaRef,
    colorTheme: theme,
    fontStyles: metaFontStyles
  }, __jsx(LandingTitle, {
    description: meta === null || meta === void 0 ? void 0 : meta.bodyText
  }))));
};

export default TemplateFour;