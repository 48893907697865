import { useContext } from 'react';

import { FontsContext } from '@anm/contexts/FontsContext';

const useFontStyles = () => {
  const { family } = useContext(FontsContext);
  const { button: buttonFontStyles, ...metaFontStyles } = family || {};

  return [buttonFontStyles, metaFontStyles] as const;
};

export default useFontStyles;
