import _objectWithoutProperties from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import getBestMedia from '@anm/helpers/getBestMedia';

var parseTemplateProps = function parseTemplateProps(_ref) {
  var page = _ref.page,
      videoRatio = _ref.videoRatio,
      playerProps = _objectWithoutProperties(_ref, ["page", "videoRatio"]);

  var isVertical = videoRatio <= 1;
  var layoutDirection = isVertical ? 'row' : 'column';
  var isCustomization = playerProps.target === 'customization';
  var parameters = page.parameters;

  var _ref2 = parameters || {},
      meta = _ref2.meta,
      cta = _ref2.cta,
      theme = _ref2.theme,
      logoUrl = _ref2.logoUrl,
      _ref2$hasFooter = _ref2.hasFooter,
      hasFooter = _ref2$hasFooter === void 0 ? true : _ref2$hasFooter;

  var isMetaExists = !!meta;
  var manifest = playerProps.manifest;
  var previewSrc = manifest !== null && manifest !== void 0 && manifest.main ? getBestMedia(manifest.main, 'image').path : null;
  var isCTA = !!(cta && !cta.disabled);
  var isOnlyCTAOrLogo = (logoUrl && !isCTA || isCTA && !logoUrl) && !isMetaExists;
  return {
    cta: cta,
    meta: meta,
    isCTA: isCTA,
    theme: theme,
    logoUrl: logoUrl,
    hasFooter: hasFooter,
    videoRatio: videoRatio,
    parameters: parameters,
    previewSrc: previewSrc,
    playerProps: playerProps,
    isMetaExists: isMetaExists,
    isOnlyCTAOrLogo: isOnlyCTAOrLogo,
    layoutDirection: layoutDirection,
    isCustomization: isCustomization
  };
};

export default parseTemplateProps;