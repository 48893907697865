import _slicedToArray from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { DESKTOP_BREAKPOINT } from '@anm/constants/videoLandings';
import getBiggestValue from '@anm/helpers/getBiggestValue';
import getContainerPaddings from '@anm/helpers/getContainerPaddings';
import getPlayerMinSizes from '@anm/helpers/getPlayerMinSizes';

var getPlayerOppositeSize = function getPlayerOppositeSize(_ref) {
  var value = _ref.value,
      videoRatio = _ref.videoRatio,
      availableSize = _ref.availableSize;
  return Math.round(availableSize === 'height' ? value * videoRatio : value / videoRatio);
};

var calculateByContainerWidth = function calculateByContainerWidth(videoRatio, containerWidth) {
  return {
    width: containerWidth,
    height: getPlayerOppositeSize({
      videoRatio: videoRatio,
      availableSize: 'width',
      value: containerWidth
    })
  };
};

var getPlayerSizes = function getPlayerSizes(_ref2) {
  var maxWidth = _ref2.maxWidth,
      maxHeight = _ref2.maxHeight,
      container = _ref2.container,
      videoRatio = _ref2.videoRatio;
  if (!maxHeight && !maxWidth) return;

  var _getPlayerMinSizes = getPlayerMinSizes(videoRatio),
      _getPlayerMinSizes2 = _slicedToArray(_getPlayerMinSizes, 2),
      minPlayerWidth = _getPlayerMinSizes2[0],
      minPlayerHeight = _getPlayerMinSizes2[1];

  var _getContainerPaddings = getContainerPaddings(container),
      _getContainerPaddings2 = _slicedToArray(_getContainerPaddings, 2),
      horizontalPaddings = _getContainerPaddings2[1];

  var containerWidth = container.offsetWidth - horizontalPaddings;
  var page = document.documentElement;
  var pageWidth = page.offsetWidth;
  var isMobile = pageWidth < DESKTOP_BREAKPOINT;

  if (isMobile) {
    return calculateByContainerWidth(videoRatio, containerWidth);
  }

  var height = maxHeight ? getBiggestValue(maxHeight, minPlayerHeight) : getPlayerOppositeSize({
    videoRatio: videoRatio,
    availableSize: 'width',
    value: maxWidth
  });
  var width = maxWidth ? getBiggestValue(maxWidth, minPlayerWidth) : getPlayerOppositeSize({
    videoRatio: videoRatio,
    availableSize: 'height',
    value: height
  });

  if (width > containerWidth) {
    return calculateByContainerWidth(videoRatio, containerWidth);
  }

  return {
    width: width,
    height: height
  };
};

export default getPlayerSizes;