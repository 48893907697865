import { useEffect } from 'react';
import routes from '../../routes';
var Router = routes.Router;

var useRedirectToError = function useRedirectToError(errorCode) {
  useEffect(function () {
    if (errorCode !== 404) return;
    Router.pushRoute('404', {}, {
      shallow: true
    });
  }, [errorCode]);
};

export default useRedirectToError;