import { FOOTER_HEIGHT } from '@anm/components/video-landings/Container';
import { DEFAULT_VIDEO_RATIO } from '@anm/constants/videoLandings';
import { useEffect, useState } from 'react';

var useContainerHeight = function useContainerHeight(_ref) {
  var hasFooter = _ref.hasFooter,
      containerRef = _ref.containerRef,
      isCustomization = _ref.isCustomization;

  var _useState = useState(),
      containerHeight = _useState[0],
      setContainerHeight = _useState[1];

  useEffect(function () {
    if (!containerRef.current || !isCustomization || containerHeight) return;
    var footerHeight = hasFooter ? FOOTER_HEIGHT : 0;
    var width = containerRef.current.offsetWidth;
    var height = width / DEFAULT_VIDEO_RATIO - footerHeight;
    false && setContainerHeight(height);
    /* TODO: remove if no needed */
  }, [containerRef === null || containerRef === void 0 ? void 0 : containerRef.current]);
  return containerHeight;
};

export default useContainerHeight;