import Image from '@anm/components/image/Image';
import { FC } from 'react';

import { LogoWrapper } from './Wrapper';

type CustomLogoProps = {
  alt: string;
  logoUrl: string;
};

const CustomLogo: FC<CustomLogoProps> = ({ alt, logoUrl }) => (
  <LogoWrapper>
    <Image src={logoUrl} {...{ alt }} />
  </LogoWrapper>
);

export default CustomLogo;
