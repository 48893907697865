import { Direction, LandingCTAProps, LandingMetaProps } from '@anm/api/modules/hosting';
import CustomLogo from '@anm/components/video-landings/CustomLogo';
import LandingCTA from '@anm/components/video-landings/LandingCTA';
import { DEFAULT_WAVE_COLOR } from '@anm/constants/colors';
import sanitizeText from '@anm/helpers/string/sanitizeText';
import { FC, RefObject } from 'react';
import { css } from 'styled-components';
import showdown from 'showdown';

import {
  CTAWrapper,
  LandingMetaWrapper,
  LandingMetaWrapperProps,
  LandingTitle,
  LogoWrapper,
  Wrapper,
  WrapperProps
} from './Wrapper';

export { Wrapper, LandingTitle, LandingMetaWrapper };

type DataWrapperProps = {
  videoId: string;
  cta?: LandingCTAProps;
  meta?: LandingMetaProps;
  isCTA?: boolean;
  logoUrl?: string;
  maxWidth?: number;
  CTAFontStyles?: ReturnType<typeof css>;
  landingCTARef?: RefObject<HTMLDivElement>;
  landingMetaRef?: RefObject<HTMLDivElement>;
  dataWrapperRef?: RefObject<HTMLDivElement>;
  layoutDirection?: Direction;
} & Partial<WrapperProps & LandingMetaWrapperProps>;

const DataWrapper: FC<DataWrapperProps> = ({
  cta,
  meta,
  isCTA = true,
  videoId,
  fontStyles,
  logoUrl,
  maxWidth,
  colorTheme = 'pure',
  CTAFontStyles,
  landingCTARef,
  dataWrapperRef,
  landingMetaRef,
  layoutVariant = 't1',
  layoutDirection = 'column',
  playerColor = DEFAULT_WAVE_COLOR
}) => {
  const isMetaExists = !!(meta?.heading || meta?.bodyText);
  const isDataExists = isMetaExists || isCTA;

  const converter = new showdown.Converter();
  const markedBodyText = meta?.bodyText ? converter.makeHtml(meta.bodyText) : '';

  return (
    <Wrapper ref={dataWrapperRef} isNoMeta={!isMetaExists} {...{ layoutDirection, maxWidth, layoutVariant }}>
      {logoUrl && (
        <LogoWrapper>
          <CustomLogo {...{ logoUrl }} alt={meta?.heading || logoUrl} />
        </LogoWrapper>
      )}
      {isDataExists && (
        <LandingMetaWrapper {...{ fontStyles, colorTheme, playerColor }} ref={landingMetaRef}>
          {isMetaExists && (
            <LandingTitle title={sanitizeText(meta?.heading)} description={markedBodyText} className="title_block" />
          )}
          {isCTA && (
            <CTAWrapper className="cta_wrapper" /* TODO: investigate SC issue about className changes */>
              <LandingCTA
                {...{ cta, playerColor }}
                theme={colorTheme}
                projectId={videoId}
                CTAref={landingCTARef}
                fontStyles={CTAFontStyles}
              />
            </CTAWrapper>
          )}
        </LandingMetaWrapper>
      )}
    </Wrapper>
  );
};

export default DataWrapper;
