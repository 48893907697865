import styled from 'styled-components';

export const LogoWrapper = styled.div`
  height: 36px;

  img {
    height: 100%;
    display: block;
  }
`;
