import { LandingCTAProps, LandingTheme } from '@anm/api/modules/hosting';
import { DEFAULT_WAVE_COLOR } from '@anm/constants/colors';
import sanitizeText from '@anm/helpers/string/sanitizeText';
import { FC, RefObject, MouseEvent } from 'react';
import { css } from 'styled-components';

import { CTAButton, LandingCTAWrapper } from './Wrapper';
import useCTAClick from './hooks';
import isInIframe from '@anm/helpers/is/isInIframe';

export { LandingCTAWrapper };

type CTAProps = {
  projectId: string;
  cta?: LandingCTAProps;
  theme?: LandingTheme;
  CTAref?: RefObject<HTMLDivElement>;
  isCentered?: boolean;
  fontStyles?: ReturnType<typeof css>;
  playerColor?: string;
};

const LandingCTA: FC<CTAProps> = ({
  cta,
  CTAref,
  projectId,
  fontStyles,
  isCentered,
  theme = 'pure',
  playerColor = DEFAULT_WAVE_COLOR
}) => {
  const isCTAExists = !!(cta && cta.buttonText && !cta.disabled);

  const onCTAClick = useCTAClick(projectId, cta);

  const isIframe = isInIframe();

  const handleClick = (e: MouseEvent) => {
    onCTAClick();

    if (isIframe && cta?.buttonLink) {
      e.preventDefault();
      window.parent.location.href = cta.buttonLink;
    }
  };

  if (!isCTAExists) return null;

  return (
    <LandingCTAWrapper ref={CTAref} isCentered={isCentered}>
      <CTAButton
        {...{ fontStyles }}
        size="large"
        buttonTheme={theme}
        variant="no-wrapper-centered"
        bgColor={playerColor}
        onClick={handleClick}
        href={cta?.buttonLink || '#'}
      >
        {sanitizeText(cta?.buttonText)}
      </CTAButton>
    </LandingCTAWrapper>
  );
};

export default LandingCTA;
