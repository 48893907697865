import { FormatNames } from '@anm/api/modules/hosting';
import { DEFAULT_VIEW_TYPE } from '@anm/constants/videoLandings';
import isInIframe from '@anm/helpers/is/isInIframe';
import { useCallback, useEffect, useState } from 'react';

const useChangeViewType = (isCustomization = false) => {
  const [viewType, setViewType] = useState<FormatNames>(DEFAULT_VIEW_TYPE);

  const onReceiveMessage = useCallback(({ data }: MessageEvent) => {
    data.viewType && setViewType(data.viewType);
  }, []);

  useEffect(() => {
    if (!isInIframe() || !isCustomization) return;

    window.addEventListener('message', onReceiveMessage);

    return () => window.removeEventListener('message', onReceiveMessage);
  }, [isCustomization]);

  return viewType;
};

export default useChangeViewType;
