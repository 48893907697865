import PlayerContainer from '@anm/components/PlayerContainer';
import Image from '@anm/components/image/Image';
import isInIframe from '@anm/helpers/is/isInIframe';
import noop from '@anm/helpers/noop';
import useResize from '@anm/hooks/useResize';
import useSwitchState from '@anm/hooks/useSwitchState';
import { Manifest, Target } from '@anm/hosting-player';
import { useCallback, useEffect, useRef, FC, RefObject } from 'react';

import { ContainerWrapper, Preview, Wrapper } from './Wrapper';

export { Wrapper };

export type Sizes = {
  width: number;
  height: number;
};

type PlayerWrapperProps = {
  videoId: string;
  previewSrc: string | null;
  playerWrapperRef: RefObject<HTMLDivElement>;
  manifest?: Manifest;
  startTime?: number;
  videoName?: string;
  playerSizes?: Sizes;
  onPlayerInit?(): void;
} & Target;

const PlayerWrapper: FC<PlayerWrapperProps> = ({
  startTime,
  videoName,
  previewSrc,
  playerSizes,
  playerWrapperRef,
  onPlayerInit = noop,
  ...playerProps
}) => {
  const [playerInited, setPlayerInited] = useSwitchState();
  const [resized, setResized] = useSwitchState();

  const playerContainerRef = useRef<HTMLDivElement>(null);

  useResize(() => !isInIframe() && setResized());

  const handlePlayerInit = useCallback(() => {
    onPlayerInit();
    setPlayerInited();
  }, [playerInited, onPlayerInit]);

  useEffect(() => {
    if (!playerContainerRef.current || playerInited || !window.wavePlayer) {
      return;
    }

    window.wavePlayer.init?.(playerContainerRef.current);
  }, [playerContainerRef]);

  const { manifest } = playerProps;

  const canShowPreview = !startTime && previewSrc && !playerInited && !manifest?.error && !manifest?.configs.autoplay;

  return (
    <Wrapper ref={playerWrapperRef} {...{ resized, playerInited }} {...playerSizes}>
      {canShowPreview && (
        <Preview>
          <Image src={previewSrc!} alt={videoName} />
        </Preview>
      )}
      <ContainerWrapper isInited={playerInited}>
        <PlayerContainer {...playerProps} {...{ startTime, playerContainerRef }} onPlayerInit={handlePlayerInit} />
      </ContainerWrapper>
    </Wrapper>
  );
};

export default PlayerWrapper;
