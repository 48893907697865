import _slicedToArray from "/home/buildagent/buildAgent/work/35e0d48fca3726c0/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { FOOTER_HEIGHT } from '@anm/components/video-landings/Container';
import getBiggestValue from '@anm/helpers/getBiggestValue';
import getContainerPaddings from '@anm/helpers/getContainerPaddings';
import getElementOuterHeight from '@anm/helpers/getElementOuterHeight';
import getPlayerMinSizes from '@anm/helpers/getPlayerMinSizes';
import { useEffect, useState } from 'react';
import getPlayerSizes from '../helpers/getPlayerSizes';

var usePlayerSizesByHeight = function usePlayerSizesByHeight(_ref) {
  var viewType = _ref.viewType,
      hasFooter = _ref.hasFooter,
      videoRatio = _ref.videoRatio,
      containerRef = _ref.containerRef,
      landingCTARef = _ref.landingCTARef,
      landingMetaRef = _ref.landingMetaRef,
      playerWrapperRef = _ref.playerWrapperRef,
      maxHeightOfScreen = _ref.maxHeightOfScreen,
      _ref$noCalculate = _ref.noCalculate,
      noCalculate = _ref$noCalculate === void 0 ? false : _ref$noCalculate,
      _ref$layoutDirection = _ref.layoutDirection,
      layoutDirection = _ref$layoutDirection === void 0 ? 'column' : _ref$layoutDirection;

  var _useState = useState(),
      playerSizes = _useState[0],
      setPlayerSizes = _useState[1];

  var _useState2 = useState(),
      playerMaxHeight = _useState2[0],
      setPlayerMaxHeight = _useState2[1];

  var footerHeight = hasFooter ? FOOTER_HEIGHT : 0;
  useEffect(function () {
    if (!playerMaxHeight || !(playerWrapperRef !== null && playerWrapperRef !== void 0 && playerWrapperRef.current) || !containerRef.current || noCalculate) {
      return;
    }

    var sizes = getPlayerSizes({
      videoRatio: videoRatio,
      maxHeight: playerMaxHeight,
      container: containerRef.current
    });
    sizes && setPlayerSizes({
      width: sizes.width,
      height: sizes.height
    });
  }, [playerMaxHeight, playerWrapperRef, containerRef, viewType]);
  useEffect(function () {
    if (!playerWrapperRef.current || !containerRef.current || noCalculate) {
      return;
    }

    var page = document.documentElement;
    var pageHeight = page.offsetHeight;
    var landingCTA = landingCTARef === null || landingCTARef === void 0 ? void 0 : landingCTARef.current;
    var landingMeta = landingMetaRef === null || landingMetaRef === void 0 ? void 0 : landingMetaRef.current;
    var shouldSubstractCTA = !landingMeta && landingCTA;

    var _getPlayerMinSizes = getPlayerMinSizes(videoRatio),
        _getPlayerMinSizes2 = _slicedToArray(_getPlayerMinSizes, 2),
        minPlayerHeight = _getPlayerMinSizes2[1];

    var screenPercentageHeight = maxHeightOfScreen && maxHeightOfScreen * pageHeight;

    if (screenPercentageHeight) {
      setPlayerMaxHeight(getBiggestValue(screenPercentageHeight, minPlayerHeight));
      return;
    }

    var rowSiblingsElements = shouldSubstractCTA ? [landingCTA] : [];

    var _getContainerPaddings = getContainerPaddings(containerRef.current),
        _getContainerPaddings2 = _slicedToArray(_getContainerPaddings, 1),
        verticalPaddings = _getContainerPaddings2[0];

    var siblingsElements = layoutDirection === 'row' ? rowSiblingsElements : Array.from(containerRef.current.children).filter(function (el) {
      return el !== playerWrapperRef.current;
    });
    var totalElementsHeight = siblingsElements.reduce(function (acc, element) {
      return acc + getElementOuterHeight(element);
    }, 0) + footerHeight;
    var calculatedVideoHeight = pageHeight - verticalPaddings - totalElementsHeight;
    setPlayerMaxHeight(getBiggestValue(calculatedVideoHeight, minPlayerHeight));
  }, [playerWrapperRef, containerRef, viewType]);
  return playerSizes;
};

export default usePlayerSizesByHeight;